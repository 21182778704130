.welcomebar {
    min-width: 400px;
    margin: 40px auto 20px auto;
    border: 1px solid #e4e4e4;
    width: 55%;

    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 6px 24px rgba(0, 0, 0, 0.055);

    .welcome-message {
        font-size: 19px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
