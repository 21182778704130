@import "antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;400;500;600&display=swap");

* {
    font-family: "Dosis", sans-serif;
}

.ant-select,
.ant-input {
    width: 100%;
    height: 45px;

    &:disabled {
        background: #fefefe;
    }
}

.ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f;
    font-size: 15px;
    font-weight: 500;
}

.ant-picker {
    height: 45px;
    background: #fefefe;
}

.ant-picker-separator {
    height: auto;
}

.ant-btn-primary {
    color: #fff;
    background: #1890ff;
    border-color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    margin-top: 0px;
}

.ant-btn.ant-btn-primary {
    width: 100px;
    font-weight: 600;
    background: #feb617;
    color: #000;

    &:disabled {
        background: #e1e1e1;
        color: rgb(126, 126, 126);
    }
}

.ant-select-multiple .ant-select-selector,
.ant-input {
    height: 45px;
    background: #fefefe;
    overflow-y: scroll;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #f4f8fa inset !important;
    }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 45px;
    background: #fff;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    line-height: 45px;
}

.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #30355e;
    border-color: #30355e;
}

.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 45px;
}

.container {
    margin: 0 auto;
    width: 55%;

    @media only screen and (max-width: 600px) {
        width: 90%;
    }

    [role="alert"] {
        font-weight: 600;
        font-size: 15px;
    }

    .spinner {
        display: flex;
        justify-content: center;
    }

    .form {
        margin: 20px auto 50px auto;
        box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12);
        padding: 30px;
        border-radius: 7px;
        border: 1px solid #e4e4e4;

        @media only screen and (max-width: 500px) {
            box-shadow: none;
            padding: 0px;
            border: none;
        }

        .heading {
            font-size: 28px;
            display: flex;
            align-items: center;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .cursor-pointer {
            font-size: 18px;
            margin-left: 5px;
            cursor: pointer;
        }

        .form-container {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
            column-gap: 30px;
            background: #fff;

            label {
                font-weight: 600;
                font-size: 16px;
            }

            .year {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }
    }

    .result {
        max-width: 500px;
        max-height: 200px;
        margin: 0 auto;
        border-radius: 7px;
        display: grid;
        column-gap: 30px;
        margin-bottom: 120px;
        box-shadow: 0px 40px 24px rgba(0, 0, 0, 0.12);

        .price-table {
            text-align: center;
            margin-bottom: 30px;

            .price-type {
                font-size: 16px;
                font-weight: 700;
                border: 1px solid grey;
                display: block;
                padding: 9px;
                color: rgb(255, 182, 25);
                background: rgb(48, 52, 94);
            }

            .quality-type {
                border: 1px solid rgb(240, 237, 237);
                padding: 0px 0px;
                background: #f9f9f9;
                font-size: 16px;
                position: relative;

                .down-icon {
                    background-color: #feb617;
                    border-radius: 50%;
                    padding: 6px;
                    right: 10px;
                    top: 82px;
                    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
                    z-index: 5;
                    font-size: 12px;
                    cursor: pointer;
                    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
                    opacity: 0;
                    position: absolute;

                    &.rotate {
                        transform: rotate(-180deg);
                        transition: all 300ms ease-in-out;
                    }
                }

                .other-price {
                    overflow: hidden;
                    height: max-content;
                    height: 100px;
                    transition: height 300ms
                        cubic-bezier(0.68, -0.55, 0.265, 1.55);

                    &.show-price {
                        height: 0px;
                    }
                }

                &:hover .down-icon {
                    display: block;
                    opacity: 1;
                }

                .min-max,
                .values {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    background-color: #e6e6e5;
                    padding: 10px 0;

                    .max-value,
                    .min-value {
                        font-size: 20px;
                        text-transform: capitalize;
                        font-weight: 600 !important;
                        letter-spacing: 1px;
                    }

                    .min,
                    .max {
                        font-weight: 600 !important;
                        text-transform: capitalize;
                    }
                }

                .values {
                    background: rgb(249, 249, 249);
                    padding: 10px 0;
                }
            }

            .type-name {
                display: block;
                padding: 9px;
                background: #feb617;

                text-transform: capitalize;
                font-weight: 600;
                font-size: 17px;
            }
        }
    }

    .rating {
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 90px;

        .rating {
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;

            span:last-child {
                background-color: #feb617;
                padding: 0 10px;
                margin-left: 10px;
                border-radius: 3px;
            }
        }
    }

    .white-1 {
        background: #fbfbfb !important;
    }

    .white-2 {
        background: #f3f3f3 !important;
    }
}
