.navbar {
    height: 60px;
    background-color: #fff;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

    .logout {
        cursor: pointer;
        color: #000;
        font-size: 18px;
    }

    .icon-holder {
        .icon {
            width: 120px;
        }
    }
}
