
    .loader {
        width: 40px;
        height: 40px;
        border: 3px solid #ffb619;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        text-align: center;
        // margin: $px 0;
    }

    .loader::after {
        content: "";
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-bottom-color: #212746;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

